import * as React from "react"
import Layout from "../../components/Layout"
import ContentFullWidthSubLayout from "../../components/ContentFullWidthSubLayout"
import { Helmet } from "react-helmet"
import Seo from "../../components/Seo"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout>
            <Seo title="Press Release: Zanbato Partners with OPEN to Power NYSE OPEN Venture Capital Unicorn Index with Secondary Market Data" />
            <div className=" py-16">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <span className="block text-base text-center text-primary font-semibold tracking-wide uppercase">
              Press Release
            </span>
            <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Zanbato Partners with OPEN to Power NYSE OPEN Venture Capital Unicorn Index with Secondary Market Data
            </span>
            <span className="mt-8 block text-sm text-center leading-8 tracking-tight text-gray-900">- September 3, 2024 -</span>
          </h1>
          <p className="mt-8 text-xl text-gray-500 leading-8">
          </p>
        </div>
        <div className="mt-6 prose prose-indigo text-gray-500 mx-auto prose-h1:text-3xl prose-h1:pt-4 prose-h2:my-3">
                <div>
                    <p>
                        San Francisco, CA — September 3, 2024 — Zanbato, a leading<sup>1</sup> platform for private market data and trading, is excited to announce its partnership with OPEN, a New York Stock Exchange-backed fund manager focused on bringing index products to private markets. Through this collaboration, Zanbato will serve as a key data provider for the <Link to="/unicorn-index">NYSE OPEN Venture Capital Unicorn Index (NYSEOVC)</Link>, an innovative new benchmark designed to track the performance of 50 prominent U.S.-based Unicorns. The partnership allows OPEN's benchmark to leverage Zanbato's pre-IPO secondary market data.
                    </p>
                    <p>
                        Zanbato is a financial technology company established to make private markets more liquid and reliable. Launched in 2016, Zanbato's ZX platform is an independent, SEC-registered Alternative Trading System that is today the leading inter-broker platform for trading venture-backed private shares. More than 220 trading groups at banks and brokers trust ZX for price discovery, liquidity, and trade execution services across private markets globally. Zanbato's ZXData offering is the leading<sup>2</sup> provider of institutional, non-tender secondary trading data on private companies. To learn more about how to invest, trade, and access data with Zanbato, visit <a href="https://zanbato.com" target="_blank">zanbato.com</a>.
                    </p>
                    <h1>
                        OPEN Brings Transparency And Access To Private Markets
                    </h1>
                    <p>
                        OPEN's new index provides deeper insights into the performance and valuation trends of major private venture capital-backed companies. Intercontinental Exchange (ICE) administers the index, ensuring that investors who follow the index benefit from ICE's robust calculation and administrative services.
                    </p>
                    <p>
                        “We are delighted to partner with OPEN. Initiatives like the NYSE OPEN Venture Capital Unicorn Index are essential in making private markets more accessible to a broader range of investors. ICE's role as the index administrator helps institutionalize the market, which aligns with our mission” said Akrati Johari, Chief Growth Officer and Head of ZXData at Zanbato.
                    </p>
                    <p>
                    “OPEN is dedicated to democratizing access to private markets. Our data partnership with Zanbato helps us fulfill this mission by providing the market with greater transparency into some of the most dynamic and fast-growing private companies,” remarked David Shapiro, CEO at OPEN.
                    </p>
                    <p>
                    Zanbato and OPEN are committed to driving innovation in private markets.
                    </p>

                    <h1>
                        About Zanbato
                    </h1>
                    <p>
                        Zanbato offers data and liquidity solutions for the private market, specializing in VC-backed private companies. Zanbato Securities LLC, a member of FINRA/SIPC, is a wholly-owned subsidiary of Zanbato, Inc. Check the background of Zanbato Securities LLC on FINRA's BrokerCheck. For more information, visit <a href="https://zanbato.com" target="_blank">zanbato.com</a>.
                    </p>

                    <h1>
                        About OPEN
                    </h1>
                    <p>
                    OPEN is revolutionizing index investing in the late-stage venture capital space, offering improved access to the world's leading private Unicorns. Our mission is to democratize venture capital investment with low-cost, institutional-grade products. With a focus on reducing fees and leveraging the power of index investing, we are committed to making this asset class accessible to all investors. For more information, visit <a href="https://www.openvc.com">www.openvc.com</a>.
                    </p>

                    <p className="text-sm">
                        1. Based on website disclosures by other market participants of the size of their bank and brokerage membership compared to ZX's over 220 subscribers<br></br>
                        2. Based on website disclosures by other private market data providers on the size of their closed trade data compared to ZXData's of over $18bn in volume
                    </p>
                    
                </div>
                </div>
      </div>
    </div>
        </Layout>
    )
}

export default IndexPage
